import React, { useEffect, useState } from 'react';
import { PatientName, AddressName, InfoTypography, DataTypography, Time, TimeBox } from './styles';
import { Container, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from 'src/components/shared/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchTokenAppointmentnameRequest } from 'src/modules/app/store/appActions';



function Infomation() {


  const [tokenAppointmentDatas, setTokenAppointmentDatas] = useState('');

  const tokenAppointmentData = useSelector((state) => state.app.tokenAppointments);
  const formatTime = (time) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(time).toLocaleTimeString(undefined, options);
  };

  useEffect(() => {

    if (tokenAppointmentData && Array.isArray(tokenAppointmentData)) {
      const latestRecords = tokenAppointmentData
        .slice(0, 10);
      console.log('Latest 10 Records:', latestRecords);
      setTokenAppointmentDatas(latestRecords);
    } else {
      console.error('Medical records is not an array or is undefined');
    }
  }, [tokenAppointmentData]);
  return (
    <>
      {tokenAppointmentDatas && tokenAppointmentDatas.length > 0 ? (
        tokenAppointmentDatas.map((appointment, index) => (
          <Box
            sx={{
              padding: '20px',
              width: '86%',
              backgroundColor: 'white',
              borderRadius: '16px',
              boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              bottom: '20px',
              right: '2.5%',
              justifyContent: 'space-between',
              marginTop: '15px',
              marginBottom: '10px'
            }}
          >

            <Grid container direction="column">
              <Box p={1} sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ marginRight: 2 }}>
                  {appointment.name ? appointment.name.charAt(0).toUpperCase() : 'A'}

                </Avatar>


                <Box sx={{ width: '200px' }}>
                  <PatientName variant="h4">{appointment.name}</PatientName>
                  {/* <AddressName variant="subtitle2">1338, Emily Flat, Reginaldtown</AddressName> */}
                </Box>
                <Box>
                  <Stack
                    direction={{ sm: 'row' }}
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                    // alignItems="center"
                    spacing={2}
                    position="relative"
                  // left="35%"
                  >
                    <Box >
                      <InfoTypography variant="body1">Age</InfoTypography>
                      <DataTypography variant="body2">{appointment.age} Year</DataTypography>
                    </Box>

                    <Box>
                      <InfoTypography variant="body1">Sex</InfoTypography>
                      <DataTypography variant="body2">{appointment.gender}</DataTypography>
                    </Box>
                    <Box>
                      <InfoTypography variant="body1">Contact No</InfoTypography>
                      <DataTypography variant="body2">{appointment.mobileNo}</DataTypography>
                    </Box>

                    <Box>
                      <InfoTypography variant="body1">Basic</InfoTypography>
                      <DataTypography variant="body2">{appointment.symptoms}</DataTypography>
                    </Box>

                  </Stack>
                </Box>
                <TimeBox>
                  <Time>{formatTime(appointment.time)}</Time>
                </TimeBox>
              </Box>
            </Grid>

          </Box>)
        )) : (
        <Typography>No Data available</Typography>

      )
      }
    </>
  );
}

export default Infomation;

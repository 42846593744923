import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchPatientInfoRequest, fetchTagsRequest, fetchAreaRequest } from 'src/modules/app/store/appActions';
import { Box, Grid, Stack, Typography, Avatar, Divider, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save'; 
import { useTheme } from '@mui/material/styles';
import {
  PatientName,
  AddressName,
  InfoTypography,
  DataTypography,
  StyledNotificationsIcon,
} from './styles';
import Edit from 'src/assets/images/welcome/EditIcon.svg';
import { palette } from 'src/config/theme';

function Information() {
  const location = useLocation();

  // const { state } = useLocation();
  const { patient } = location.state || {};
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tags = useSelector((state) => state.app.tags) || [];
  const area = useSelector((state) => state.app.areas) || [];
  const clinic = useSelector((state) => state.app.clinics) || {};
  const patientInfoResponse = useSelector((state) => state.app.patientInfo) || [];

  const [tagsLoaded, setTagsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false); 


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      if (patient && patient.id) {
        try {
          await dispatch(fetchPatientInfoRequest(patient.id));
          await dispatch(fetchTagsRequest(patient.id));
          await dispatch(fetchAreaRequest(patient.id));
        } catch (err) {
          setError('Failed to fetch data');
          console.error('Error fetching data:', err);
        } finally {
          setTagsLoaded(true);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
  }, [patient, dispatch]);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };
  const handleSaveClick = () => {
    // Save updated values logic
    setIsEditMode(false);
    // console.log('Saved:', { editedAge, editedWeight, editedHeight });
  };

  const handleTagsClick = () => {
    if (patient && clinic) {
      navigate(`/${clinic.clinicId}/${clinic.doctorId}/tag/${patient.id}`, {
        state: { patient },
      });
    }
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  const patientInfo = (Array.isArray(patientInfoResponse) && patientInfoResponse[0]) || {};
  const weight = patientInfo.weight || 'N/A';
  const height = patientInfo.height || 'N/A';

  return (
    <>
      {patient ? (
        <Box
          sx={{
            padding: '20px',
            width: '82%',
            backgroundColor: 'white',
            borderRadius: '16px',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            bottom: '20px',
            justifyContent: 'space-between',
            [theme.breakpoints.down('lg')]: {
              height: '20vh',
            },
            [theme.breakpoints.down('md')]: {
              height: '20vh',
            },
            [theme.breakpoints.down('sm')]: {
              height: '20vh',
            },
          }}
        >
          <Grid container direction="column">
            <Box p={1} sx={{ display: 'flex', alignItems: 'start' }}>
              <Avatar sx={{ marginRight: 2 }} />
              <Box>
                <PatientName variant="h4">{patient.name || 'N/A'}</PatientName>
                {/* <AddressName variant="subtitle2">{area?.area || 'N/A'}</AddressName> Displaying area */}
                {area.length > 0 ? (
  area.flatMap((area) =>
    Array.isArray(area.area) ? (
      area.area.map((areaName, index) => (
        <AddressName key={`${area.id}-${index}`} variant="subtitle2">
          {areaName}
        </AddressName>
      ))
    ) : (
      <AddressName
        key={`${area.id}`}
        variant="subtitle2"
      >
        {area.area || 'No areas available'}
      </AddressName>
    )
  )
) : (
  <Typography
    variant="body2"
    sx={{
      color: palette.primary.main,
      fontWeight: 'bold',
      position: 'relative',
      top: '5px',
    }}
  >
    No areas available
  </Typography>
)}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Stack direction="row" spacing={2}>
                  <Box>
                    <InfoTypography variant="body1">Age</InfoTypography>
                    <DataTypography variant="body2">{patient.age || 'N/A'} Year</DataTypography>
                  </Box>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ backgroundColor: '#B7B7B7', height: 40 }}
                  />
                  <Box>
                    <InfoTypography variant="body1">Sex</InfoTypography>
                    <DataTypography variant="body2">{patient.gender || 'N/A'}</DataTypography>
                  </Box>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ backgroundColor: '#B7B7B7', height: 40 }}
                  />
                  <Box>
                    <InfoTypography variant="body1">Weight</InfoTypography>
                    <DataTypography variant="body2">
                      {weight}
                    </DataTypography>
                  </Box>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ backgroundColor: '#B7B7B7', height: 40 }}
                  />
                  <Box>
                    <InfoTypography variant="body1">Height</InfoTypography>
                    <DataTypography variant="body2">
                      {height}
                    </DataTypography>
                  </Box>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ backgroundColor: '#B7B7B7', height: 40 }}
                  />
                  <Box>
                    <InfoTypography variant="body1">Basic</InfoTypography>
                    <DataTypography variant="body2">{patient.symptoms || 'N/A'}</DataTypography>
                  </Box>
                </Stack>
                {/* <Avatar src={Edit} sx={{ cursor: 'pointer' }} onClick={handleEditClick} /> */}
                <IconButton onClick={isEditMode ? handleSaveClick : handleEditClick}>
                {isEditMode ? <SaveIcon /> : <EditIcon />} {/* Switch between Save and Edit icon */}
              </IconButton>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', sm: 'flex-start' },
                position: 'relative',
                left: '2%',
              }}
            >
              <StyledNotificationsIcon sx={{ marginRight: 1 }} />
              <Typography
                variant="body1"
                onClick={handleTagsClick}
                sx={{
                  color: palette.primary.main,
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  display: 'flex',
                  gap:2
                }}
              >
                {tagsLoaded ? (
                  tags.length > 0 ? (
                    tags.flatMap((tag) =>
                      Array.isArray(tag.tag) ? (
                        tag.tag.map((tagName, index) => (
                          <Typography key={`${tag.id}-${index}`} variant="body2">
                            {tagName}
                          </Typography>
                        ))
                      ) : (
                        <Typography
                          key={`${tag.id}`}
                          variant="body2"
                          sx={{
                            color: palette.primary.main,
                            fontWeight: 'bold',
                          }}
                        >
                          No tags available
                        </Typography>
                      )
                    )
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{
                        color: palette.primary.main,
                        fontWeight: 'bold',
                        position: 'relative',
                        top: '5px',
                      }}
                    >
                      No tags available
                    </Typography>
                  )
                ) : (
                  'Outstanding / Missed 2 Followup / Referred or some important notes'
                )}
              </Typography>
            </Box>
          </Grid>
        </Box>
      ) : (
        <Typography variant="h6">No patient data available</Typography>
      )}
    </>
  );
}

export default Information;

import { all, fork, takeLatest, call, put } from 'redux-saga/effects';
import { jwtDecode } from 'jwt-decode';
import * as appTypes from './appTypes';
import * as appActions from './appActions';
import {
  addPatient,
  addTask,
  getclinics,
  addStaff,
  fetchStaffs,
  fetchPatients,
  fetchTasks,
  fetchTokenAppointments,
  deletePatient,
  deleteStaff,
  deleteTokenAppointment,
  addMedicalRecord,
  fetchPatientByContactNo,
  fetchPatientInfo,
  fetchMedicalRecords,
  addTag,
  addAppointment,
  fetchAvailability,
  fetchTags,
  addBill,
  addArea,
  fetchTemplate,
  AddTemplate,
  fetchAreas,
  addTreatment,
  fetchArea,
  updatePatientById, getPatientById
} from 'src/modules/app/api/appApi';
import { replace } from 'redux-first-history';
import { extractParams, replaceParams } from 'src/utils/url';
import storage from 'src/utils/storageUtils';
import { renderHook } from '@testing-library/react';
import { Height } from '@mui/icons-material';

function getUserIdFromToken() {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user;
  }
  throw new Error('Token not found');
}

// fetch Patient
function* handleFetchPatients(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchPatients, token);
    if (response && response.data) {
      yield put(appActions.fetchPatientsSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchPatientsFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchPatientsFailure(error));
    if (reject) reject(error);
  }
}

function* handleFetchTemplates(action) {
  const { resolve = () => { }, reject = () => { } } = action.payload || {};
  try {
    const fetchedToken = storage.get('TOKEN');

    const response = yield call(fetchTemplate, fetchedToken);

    if (response && response.data && Array.isArray(response.data)) {
      const templates = response.data.map((template) => ({
        title: template.title,
        prescription: template.text,
        id: template.id,
      }));
      yield put(appActions.fetchTemplatesTitleSuccess(templates));

      if (resolve) resolve(templates);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchTemplatesTitleFailure({ message: error.message }));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error fetching Template:', error);
    yield put(appActions.fetchTemplatesTitleFailure({ message: error.message }));
    if (reject) reject(error);
  }
}

function* handlefetchAreas(action) {
  const { patientId } = action.payload;

  try {
    const fetchedToken = storage.get('TOKEN');

    const response = yield call(fetchAreas, patientId , fetchedToken);

    if (response && response.data ) {
      const areas = response.data.map((area) => ({
        id: area.id,
        area: area.area,
      }));
      yield put(appActions.fetchAreaSuccess(areas));

    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchAreaFailure({ message: error.message }));
      // if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error fetching Area:', error);
    yield put(appActions.fetchAreaFailure({ message: error.message }));
    // if (reject) reject(error);
  }
}

// fetch Task
function* handleFetchTasks(action) {
  
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchTasks, token);
    if (response && response.data) {
      yield put(appActions.fetchTasksSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchTasksFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchTasksFailure(error));
    if (reject) reject(error);
  }
}

// Fetch Staff
function* handleFetchStaffs(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchStaffs, token);
    if (response && response.data) {
      yield put(appActions.fetchStaffsSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchStaffsFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchStaffsFailure(error));
    if (reject) reject(error);
  }
}

function* handleFetchPatientByContactNo(action) {
  const { contactNo, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchPatientByContactNo, contactNo, token);

    if (response && response.data) {
      const { fullName, age, gender } = response.data;
      yield put(appActions.fetchPatientByContactNoSuccess({ fullName, age, gender }));
      if (resolve) resolve({ fullName, age, gender });
    } else {
      const error = new Error('Patient not found');
      yield put(appActions.fetchPatientByContactNoFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error fetching patient data:', error);
    yield put(appActions.fetchPatientByContactNoFailure(error));
    if (reject) reject(error);
  }
}

//get avialbility

function* handlefetchAvailability(action) {
  try {
    const { clinicId } = action.payload;
    const response = yield call(fetchAvailability, clinicId);

    if (response && response.data) {
      yield put(appActions.fetchAvailabilitySuccess(response.data));
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchAvailabilityFailure(error));
    }
  } catch (error) {
    console.error('Error fetching availability:', error);
    yield put(appActions.fetchAvailabilityFailure(error));
  }
}

//fetch tokenappointment
function* handleFetchTokenAppointments(action) {
  const { token, resolve, reject } = action.payload;
  // console.log('Token received:', token);
  // console.log('Resolve function:', resolve);
  // console.log('Reject function:', reject);

  try {
    const fetchedToken = storage.get('TOKEN');
    // console.log('Token from storage:', fetchedToken);

    const response = yield call(fetchTokenAppointments, fetchedToken);
    // console.log('Fetched response:', response);

    if (response && response.data && Array.isArray(response.data)) {
      console.log("Tokenappointment Datas", response.data)
      const tokenAppointments = response.data.map((appointment) => ({
        id: appointment.id,
        date: appointment.date,
        time: appointment.time,
        symptoms: appointment.addnotes,
        name: appointment.patient.fullName,
        patientid: appointment.patient.id,
        age: appointment.patient.age,
        mobileNo: appointment.patient.contactNo,
      }));
      yield put(appActions.fetchTokenAppointmentsSuccess(tokenAppointments));

      if (resolve) resolve(tokenAppointments);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchTokenAppointmentsFailure({ message: error.message }));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error fetching token appointments:', error);
    yield put(appActions.fetchTokenAppointmentsFailure({ message: error.message }));
    if (reject) reject(error);
  }
}
//fetch patientname tokenappointment
function* handleFetchTokenAppointmentname(action) {
  const { resolve = () => { }, reject = () => { } } = action.payload || {};
  try {
    const fetchedToken = storage.get('TOKEN');

    const response = yield call(fetchTokenAppointments, fetchedToken);

    if (response && response.data && Array.isArray(response.data)) {
      const tokenAppointments = response.data.map((appointment) => ({
        date: appointment.date,
        time: appointment.time,
        symptoms: appointment.addnotes,
        name: appointment.patient.fullName,
        id: appointment.patient.id,
        age: appointment.patient.age,
        mobileNo: appointment.patient.contactNo,
        tokenappointment: appointment.id,
        symptoms: appointment.addnotes,
        gender: appointment.patient.gender,
        // area: appointment.patient.area,
      }));
      yield put(appActions.fetchTokenAppointmentnameSuccess(tokenAppointments));

      if (resolve) resolve(tokenAppointments);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchTokenAppointmentnameFailure({ message: error.message }));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error fetching token appointments:', error);
    yield put(appActions.fetchTokenAppointmentnameFailure({ message: error.message }));
    if (reject) reject(error);
  }
}
function* handleAddPatient(action) {
  try {
    const { data, resolve, reject } = action.payload?.data || {};
    const updatedData = {
      ...data,
      user: getUserIdFromToken(),
    };
    const newPatient = yield call(addPatient, updatedData);
    yield put(appActions.addPatientSuccess(newPatient.patient));
    if (resolve) resolve(newPatient);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addPatientFailure(error));
    if (reject) reject(error);
  }
}

//delete patient
function* handleDeletePatient(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deletePatient, id);
    if (response) {
      yield put(appActions.deletePatientSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting Patient');
      yield put(appActions.deletePatientFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting Patient:', error);
    yield put(appActions.deletePatientFailure(error));
    if (reject) reject(error);
  }
}

//delete staff
function* handleDeleteStaff(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteStaff, id);
    if (response) {
      yield put(appActions.deleteStaffSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting Satff');
      yield put(appActions.deleteStaffFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting Patient:', error);
    yield put(appActions.deleteStaffFailure(error));
    if (reject) reject(error);
  }
}

//delete tokenappointment

function* handleDeleteTokenAppointment(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteTokenAppointment, id);

    if (response) {
      yield put(appActions.deleteTokenAppointmentSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting Token Appointment');
      yield put(appActions.deleteTokenAppointmentFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting Token Appointment:', error);
    yield put(appActions.deleteTokenAppointmentFailure(error));
    if (reject) reject(error);
  }
}

function* handleAddTask(action) {
  try {
    const { data, resolve, reject } = action.payload?.data || {};
    const updatedData = {
      ...data,
      // user: getUserIdFromToken(),
    };
    const newTask = yield call(addTask, updatedData);
    yield put(appActions.addTaskSuccess(newTask.task));
    if (resolve) resolve(newTask);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addTaskFailure(error));
    if (reject) reject(error);
  }
}

function* handlePostTreatment(action) {
  try {
    const { data, resolve, reject } = action.payload?.data || {};
    const updatedData = {
      ...data,
      // user: getUserIdFromToken(),
    };
    const newTreatment = yield call(addTreatment, updatedData);
    yield put(appActions.postTreatmentSuccess(newTreatment.treatment));
    if (resolve) resolve(newTreatment);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.postTreatmentFailure(error));
    if (reject) reject(error);
  }
}

function* handleAddStaff(action) {
  try {
    const { data, resolve, reject } = action.payload?.data || {};
    const updatedData = {
      ...data,
      // user: getUserIdFromToken(),
    };
    const newStaff = yield call(addStaff, updatedData);
    yield put(appActions.addStaffSuccess(newStaff.staff));
    if (resolve) resolve(newStaff);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addStaffFailure(error));
    if (reject) reject(error);
  }
}
function* handlePostMedicalNotes(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
      ...data,
      // user: getUserIdFromToken(),
    };
    // Ensure addMedicalRecord function is correctly implemented
    const newMedicalNotes = yield call(addMedicalRecord, updatedData);
    yield put(appActions.postMedicalNotesSuccess(newMedicalNotes.medicalRecord));
  } catch (error) {
    yield put(appActions.postMedicalNotesFailure(error));
    if (action.payload.reject) {
      action.payload.reject(error);
    }
  }
}

//post Prescription
function* handlePostPrescription(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
      ...data,
      // user: getUserIdFromToken(),
    };
    // Ensure addMedicalRecord function is correctly implemented
    const newMedicalPrescription = yield call(addMedicalRecord, updatedData);
    yield put(appActions.postMedicalPrescriptionSuccess(newMedicalPrescription.medicalRecord));
  } catch (error) {
    yield put(appActions.postMedicalPrescriptionFailure(error));
    if (action.payload.reject) {
      action.payload.reject(error);
    }
  }
}

//post tag
function* handlePostTag(action) {
  try {
    const { data, resolve, reject } = action.payload?.data || {};
    const updatedData = {
      ...data,
      user: getUserIdFromToken(),
    };
    const newTag = yield call(addTag, updatedData);
    yield put(appActions.addTagSuccess(newTag, updatedData));
  } catch (error) {
    yield put(appActions.addTagFailure(error));
    if (action.payload.reject) {
      action.payload.reject(error);
    }
  }
}

// poat bill
function* handlePostBill(action) {
  try {
    const { data, resolve, reject } = action.payload?.data || {};
    const updatedData = {
      ...data,
      user: getUserIdFromToken(),
    };
    const newBill = yield call(addBill, updatedData);
    yield put(appActions.addBillSuccess(newBill, updatedData));
  } catch (error) {
    yield put(appActions.addBillFailure(error));
    if (action.payload.reject) {
      action.payload.reject(error);
    }
  }
}
//Add Area
function* handleAddArea(action) {
  try {
    const { data, resolve, reject } = action.payload?.data || {};
    const updatedData = {
      ...data,
      user: getUserIdFromToken(),
    };
    const newArea = yield call(addArea, updatedData);
    yield put(appActions.addAreaSuccess(newArea.area));
  } catch (error) {
    yield put(appActions.addAreaFailure(error));
    if (action.payload.reject);
    {
      action.payload.reject(error);
    }
  }
}

function* handleFetchNotes(action) {
  try {
    const { patientId } = action.payload;
    const token = yield call(storage.get, 'TOKEN');

    const response = yield call(fetchMedicalRecords, patientId, token);

    if (response && response.data && Array.isArray(response.data)) {
      const mappedNotes = response.data
        .filter((record) => record.recordType === 'note')
        .map((record) => ({
          notes: record.notes,
          date: record.date,
          id: record.id,
        }));
      yield put(appActions.fetchNotesSuccess(mappedNotes));
    } else {
      throw new Error('No medical records found');
    }
  } catch (error) {
    console.error('Error fetching medical records:', error);
    yield put(appActions.fetchNotesFailure(error));
  }
}

//fetch Prescription
function* handleFetchPrescription(action) {
  try {
    const { patientId } = action.payload;
    const token = yield call(storage.get, 'TOKEN');

    const response = yield call(fetchMedicalRecords, patientId, token);

    if (response && response.data && Array.isArray(response.data)) {
      const mappedPrescriptions = response.data
        .filter((recordData) => recordData.recordType === 'prescription')
        .map((recordData) => ({
          prescription: recordData.notes,
          date: recordData.date,
          id: recordData.id,
        }));
      yield put(appActions.fetchPrescriptionSuccess(mappedPrescriptions));
    } else {
      throw new Error('No medical records found or invalid response structure');
    }
  } catch (error) {
    console.error('Error fetching prescriptions:', error);
    yield put(appActions.fetchPrescriptionFailure(error));
  }
}

//fetch template
function* handlePostTemplate(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
      ...data,
      // user: getUserIdFromToken(),
    };
    // Ensure addMedicalRecord function is correctly implemented
    const newTemplate = yield call(AddTemplate, updatedData);
    yield put(appActions.postTemplateSuccess(newTemplate.template));
  } catch (error) {
    yield put(appActions.postTemplateFailure(error));
    if (action.payload.reject) {
      action.payload.reject(error);
    }
  }
}

// post Appointment
function* handlePostAppointment(action) {
  try {
    const { data, resolve, reject } = action.payload;
    console.log('Saga Data:', data);

    const updatedData = {
      ...data,
      // user: getUserIdFromToken(),
    };
    console.log('Updated Data:', updatedData);

    const newAppointment = yield call(addAppointment, updatedData);
    console.log('New Appointment:', newAppointment);

    yield put(appActions.postAppointmentSuccess(newAppointment.appointment));
  } catch (error) {
    console.error('Saga Error:', error);
    yield put(appActions.postAppointmentFailure(error));

    if (action.payload.reject) {
      action.payload.reject(error);
    }
  }
}
// fetch area
// function* handleFetchArea(action) {
//   try {
//     const { areaId } = action.payload;
//     if (!areaId) {
//       throw new Error('Area ID is undefined');
//     }
//     const response = yield call(fetchAvailability, areaId);

//     if (response && response.data) {
//       yield put(appActions.fetchAreaSuccess(response.data));
//     } else {
//       const error = new Error('No data found');
//       yield put(appActions.fetchAreaFailure(error));
//     }
//   } catch (error) {
//     console.error('Error fetching area:', error);
//     yield put(appActions.fetchAreaFailure(error));
//   }
// }

//get tag

function* handleFetchTags(action) {
  try {
    const { patientId } = action.payload;
    const token = yield call(storage.get, 'TOKEN');

    // console.log('Fetching medical records for patientId:', patientId);

    const response = yield call(fetchTags, patientId, token);

    if (response && response.data) {
      // Map the medical records data to extract notes and date
      const mappedTags = response.data.map((tag) => ({
        tag: tag.tag,
        id: tag.id,
        // Add more fields as needed
      }));

      // console.log('Fetched tags:', mappedTags);

      yield put(appActions.fetchTagsSuccess(mappedTags));
    } else {
      throw new Error('No Tags found');
    }
  } catch (error) {
    console.error('Error fetching tags:', error);
    yield put(appActions.fetchTagsFailure(error));
  }
}

function* handleFetchPatientInfo(action) {
  const { patientId } = action.payload;
  const { resolve, reject } = action.meta;

  try {
    const token = yield call(storage.get, 'TOKEN');
    const response = yield call(fetchPatientInfo, patientId, token);

    if (response && response.data && Array.isArray(response.data)) {
      yield put(appActions.fetchPatientInfoSuccess(response.data));
      if (resolve) resolve(response);
    } else {
      throw new Error('No patient information found');
    }
  } catch (error) {
    yield put(appActions.fetchPatientInfoFailure({ error: error.message }));
    if (reject) reject(error);
  }
}

//Update Patient

function* handleUpdatePatient(action) {
  try {
    const { patientId, patientData } = action.payload;
    const response = yield call(updatePatientById, patientId, patientData);

    if (response.status === 200) {
      // Adjust this line based on the actual response structure
      yield put(appActions.updatePatientSuccess({ data: response.data }));
    } else {
      yield put(appActions.updatePatientFailure('No patient information updated'));
    }
  } catch (error) {
    yield put(appActions.updatePatientFailure(error.message || 'Failed to update patient'));
  }
}

//patient get by id
function* handleFetchPatientById(action) {
  try {
    const { patientId } = action.payload;
    const data = yield call(getPatientById, patientId);
    yield put(appActions.fetchPatientByIdSuccess(data));
  } catch (error) {
    yield put(appActions.fetchPatientByIdFailure(error.message || 'Failed to fetch patient data'));
  }
}
// set Clinic

function* getClinic(action) {
  try {
    yield put(appActions.setAppLoading(true));
    const referenceURL = 'http://localhost:3000/:clinicId/:doctorId/xyz';
    const location = window.location;
    const params = extractParams(referenceURL, location.href);
    const clinics = yield call(getclinics);
    const firstClinic = clinics.clinics?.[0];
    if ((!params.clinicId || !params.doctorId) && firstClinic) {
      const newUrl = replaceParams(referenceURL, location.href, firstClinic.id, firstClinic.user);
      yield put(replace(newUrl));
    }
    yield put(appActions.setClinics(clinics));
    yield put(
      appActions.setCurrentClinics({
        clinicId: params.clinicId || firstClinic.id,
        doctorId: params.doctorId || firstClinic.user,
      }),
    );
    yield put(appActions.setAppLoading(false));
  } catch (error) {
    console.error(error);
  }
}

export function* watchPatientSagas() {
  yield takeLatest(appTypes.ADD_PATIENT_REQUEST, handleAddPatient);
  yield takeLatest(appTypes.GET_CLINICS, getClinic);
  yield takeLatest(appTypes.FETCH_PATIENTS_REQUEST, handleFetchPatients);
  yield takeLatest(appTypes.FETCH_PATIENT_BY_CONTACTNO_REQUEST, handleFetchPatientByContactNo);
  yield takeLatest(appTypes.DELETE_PATIENT_REQUEST, handleDeletePatient);
  yield takeLatest(appTypes.UPDATE_PATIENT_REQUEST, handleUpdatePatient);
  yield takeLatest(appTypes.FETCH_PATIENT_BY_ID_REQUEST, handleFetchPatientById)
}

export function* watchTaskSagas() {
  yield takeLatest(appTypes.ADD_TASK_REQUEST, handleAddTask);
  yield takeLatest(appTypes.FETCH_TASKS_REQUEST, handleFetchTasks);
}
export function* watchStaffSagas() {
  yield takeLatest(appTypes.ADD_STAFF_REQUEST, handleAddStaff);
  yield takeLatest(appTypes.FETCH_STAFFS_REQUEST, handleFetchStaffs);
  yield takeLatest(appTypes.DELETE_STAFF_REQUEST, handleDeleteStaff);
}

export function* watchFetchTokenAppointmentSagas() {
  yield takeLatest(appTypes.FETCH_TOKENAPPOINTMENTS_REQUEST, handleFetchTokenAppointments);
  yield takeLatest(appTypes.POST_APPOINTMENT_REQUEST, handlePostAppointment);
  yield takeLatest(appTypes.DELETE_TOKENAPPOINTMENT_REQUEST, handleDeleteTokenAppointment);
}
export function* watchFetchTokenAppointmentnameSagas() {
  yield takeLatest(appTypes.FETCH_PATIENT_NAMES_REQUEST, handleFetchTokenAppointmentname);
}

export function* watchAddMedicalRecord() {
  yield takeLatest(appTypes.POST_MEDICAL_NOTES_REQUEST, handlePostMedicalNotes);
  yield takeLatest(appTypes.FETCH_MEDICAL_NOTES_REQUEST, handleFetchNotes);
  yield takeLatest(appTypes.POST_PRESCRIPTION_REQUEST, handlePostPrescription);
  yield takeLatest(appTypes.FETCH_PRESCRIPTIONS_REQUEST, handleFetchPrescription);
}
function* watchFetchPatientInfo() {
  yield takeLatest(appTypes.FETCH_PATIENT_INFO_REQUEST, handleFetchPatientInfo);
}
function* watchAddTag() {
  yield takeLatest(appTypes.ADD_TAG_REQUEST, handlePostTag);
  yield takeLatest(appTypes.FETCH_TAGS_REQUEST, handleFetchTags);
}
function* watchAddBill() {
  yield takeLatest(appTypes.ADD_BILL_REQUEST, handlePostBill);
}

function* watchAddArea() {
  yield takeLatest(appTypes.ADD_AREA_REQUEST, handleAddArea);
  yield takeLatest(appTypes.FETCH_AREA_REQUEST, handlefetchAreas);
  // yield takeLatest(appTypes.FETCH_AREA_REQUEST, handleFetchArea);
}
function* watchFetchAvailability() {
  yield takeLatest(appTypes.FETCH_AVAILABILITY_REQUEST, handlefetchAvailability);
}

function* watchFetchTemplates() {
  yield takeLatest(appTypes.FETCH_TEMPLATES_TITLE_REQUEST, handleFetchTemplates);
  yield takeLatest(appTypes.POST_TEMPLATE_REQUEST, handlePostTemplate);
}

function* watchAddTreatment() {
  yield takeLatest(appTypes.POST_TREATMENT_REQUEST, handlePostTreatment);
}

export default function* rootSaga() {
  yield all([
    fork(watchPatientSagas),
    fork(watchTaskSagas),
    fork(watchStaffSagas),
    fork(watchFetchTokenAppointmentSagas),
    fork(watchFetchTokenAppointmentnameSagas),
    fork(watchAddMedicalRecord),
    fork(watchFetchPatientInfo),
    fork(watchAddTag),
    fork(watchFetchAvailability),
    fork(watchAddBill),
    fork(watchAddArea),
    fork(watchFetchTemplates),
    fork(watchAddTreatment),
  ]);
}
